<template>
  <b-modal v-model="showModal" hide-footer title="Delete Contract" class="v-modal-custom" centered>
    <h5 class="fw-normal mb-4">Are you sure you want to delete <span class="fw-bolder text-danger me-1">{{
        contract.name
      }}</span>?</h5>
    <form @submit.prevent="deleteAct()">
      <input v-model="confirmation_text" class="form-control mb-3" placeholder="Yes">
      <div class="text-end">
        <b-button v-if="!is_deleting" variant="danger" :disabled="confirmation_text.trim().toUpperCase() !== 'YES'">
          <i class="bx bx bxs-trash-alt align-middle me-1"></i>
          Yes, Delete
        </b-button>
        <b-spinner v-else variant="danger" class="mb-0"></b-spinner>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {deleteContractById} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['deleted'],
  name: "document_edit",
  data() {
    return {
      showModal: false,
      confirmation_text: '',
      contract: {},
      is_deleting: false
    }
  },
  methods: {
    openModal(contract) {
      this.showModal = true
      this.contract = contract
    },
    async deleteAct() {
      if (this.confirmation_text.trim().toUpperCase() !== 'YES' || this.is_deleting) return;
      this.is_deleting = true
      let response = await deleteContractById(this.contract.id)
      if (response.ok) {
        this.showModal = false
        this.$emit('deleted')
        await Swal.fire({
          icon: 'success',
          title: 'Contract deleted successfully',
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
}
</script>

<style scoped>

</style>