<script>
import {defineComponent} from 'vue'
import {getContractsByCompanySlug} from "@/api/client/ClientApi";
import ContractCreateModal from "@/views/pages/accounts/counterparties/modals/ContractCreate.vue";
import ContractUpdateModal from "@/views/pages/accounts/counterparties/modals/ContractUpdate.vue";
import ContractDeleteModal from "@/views/pages/accounts/counterparties/modals/ContractDelete.vue"

export default defineComponent({
  name: "Contracts",
  components: {
    ContractCreateModal,
    ContractUpdateModal,
    ContractDeleteModal,
  },
  data() {
    return {
      isLoadingContracts: true,
      contracts: [],
      currentContract: {},
      mode: 'contracts',
      contractModeDetails: {},
      balance: {},
      is_loading_balance: false
    }
  },
  methods: {
    async getContracts() {
      this.isLoadingContracts = true
      let response = await getContractsByCompanySlug(this.$route.params.slug)
      this.isLoadingContracts = false
      this.contracts = response.ok ? response.data.results : []
    },
    onContractCreated() {
      this.getContracts()
    },
    setContractToLocalStorage(contract) {
      localStorage.setItem(`contract_${contract.slug}`, JSON.stringify(contract.name))
    },
  },
  mounted() {
    this.getContracts()
  }
})
</script>

<template>

  <b-card no-body>
    <b-card-body>
      <div id="folder-list" class="mb-2">
        <div class="row justify-content-between align-items-center g-2 mb-3">
          <div class="col">
            <h5 class="my-0">Contracts</h5>
          </div>
          <div class="col-auto">
            <div class="d-flex gap-2 align-items-start mb-2">
              <b-button @click="this.$refs.contractCreateModal.openModal()" variant="success" data-bs-toggle="modal"
                        data-bs-target="#CreateContractModal">
                <i class="ri-add-line align-bottom me-1"></i> Create Contract
              </b-button>
            </div>
          </div>
        </div>
        <div class="row" v-if="!isLoadingContracts">
          <template v-if="contracts.length > 0">
            <div class="col-6 col-lg-4 col-xl-3" v-for="contract in contracts" :key="contract.id">
              <div class="card bg-light shadow-none">
                <div class="card-body">

                  <div class="d-flex justify-content-between mb-1">
                    <div>
                      <router-link :to="{
                              name: 'customer_profile_contracts_acts',
                              params: {contract_slug: contract.slug }}"
                      >
                        <div class="d-flex align-items-center">
                          <i class="ri-folder-2-fill text-warning h1 me-2"></i>
                          <h5 @click="setContractToLocalStorage(contract)">{{ contract.name }}</h5>
                        </div>
                      </router-link>
                    </div>
                    <div class="dropdown">
                      <button class="btn btn-ghost-primary btn-icon btn-sm dropdown" type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                        <i class="ri-more-2-fill fs-16 align-middle"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <router-link
                              :to="{name: 'customer_profile_contracts_acts', params: {contract_slug: contract.slug}}">
                            <a @click="setContractToLocalStorage(contract)"
                               class="dropdown-item view-item-btn cursor-pointer">
                              <i class="mdi mdi-folder-open align-middle me-1"></i>
                              Open
                            </a>
                          </router-link>
                        </li>
                        <li>
                          <a data-bs-toggle="modal" data-bs-target="#UpdateContractModal"
                             class="dropdown-item view-item-btn cursor-pointer"
                             @click="this.$refs.contractUpdateModal.openModal(contract)"
                          >
                            <i class="bc bx bxs-pencil align-middle me-1"></i>
                            Edit
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="modal" data-bs-target="#DeleteContractModal"
                             class="dropdown-item view-item-btn cursor-pointer text-danger"
                             @click="currentContract = contract"
                          >
                            <i class="bx bx bxs-trash-alt align-middle me-1"></i>
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="pt-3 pb-2">
                    <div class="d-flex justify-content-start gap-2">
                      <div class="text-center">
                        <router-link
                            :to="{name: 'customer_profile_contracts_acts', params: {contract_slug: contract.slug}}">
                          <div @click="setContractToLocalStorage(contract)"
                               class="card card-body mb-0 py-1 card-animate cursor-pointer">
                            <i class="ri-folder-2-fill d-block fs-22 text-warning"></i>
                            <h5 class="mb-0">Acts</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="text-center">
                        <router-link @click="setContractToLocalStorage(contract)"
                                     :to="{name: 'customer_profile_contracts_docs', params: {contract_slug: contract.slug}}">
                          <div class="card card-body mb-0 py-1 card-animate cursor-pointer">
                            <i class="ri-folder-2-fill d-block fs-22 text-warning"></i>
                            <h5 class="mb-0">Docs</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="text-center">
                        <router-link @click="setContractToLocalStorage(contract)"
                                     :to="{name: 'customer_profile_contracts_orders', params: {contract_slug: contract.slug}}">
                          <div class="card card-body mb-0 py-1 card-animate cursor-pointer">
                            <i class="ri-folder-2-fill d-block fs-22 text-warning"></i>
                            <h5 class="mb-0">Orders</h5>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mt-4 text-muted">
                  <span>
                    {{ contract.start_date }}
                  </span>
                    <span>
                    {{ contract.expire_date }}
                  </span>
                  </div>
                </div><!----><!----></div>
            </div>
          </template>
          <template v-else>
            <div class="col-12 py-5 text-center">
              <h4 class="my-4">
                No Contracts
              </h4>
              <b-button @click="this.$refs.contractCreateModal.openModal()" variant="outline-success"
                        data-bs-toggle="modal" data-bs-target="#CreateContractModal">
                <i class="ri-add-line align-bottom me-1"></i> Create Contract
              </b-button>
            </div>
          </template>
        </div>
        <div class="text-center py-5" v-if="isLoadingContracts">
          <b-spinner label="Spinning" variant="primary"></b-spinner>
        </div>
      </div>
    </b-card-body>
  </b-card>

  <ContractCreateModal ref="contractCreateModal"
                       :company_slug="this.$route.params.slug"
                       @create="getContracts"
  />

  <ContractUpdateModal ref="contractUpdateModal"
      :company_slug="this.$route.params.slug"
      :contract="currentContract"
      @update="getContracts"
  />
  <ContractDeleteModal :contract="currentContract"
                       @delete="getContracts">
  </ContractDeleteModal>
</template>

